import React, { useEffect, useState, useCallback } from 'react'
import {PropTypes} from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import { StaticImage } from "gatsby-plugin-image"

import Vector from '../../svg/vector-techs.svg'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    background: [theme.palette.gradientDark.main],
    color: '#fff',
    padding: '85px 0',
  },

  titleBox: {
    marginBottom: '70px',
  },

  title: {
    marginBottom: '25px',
  },

  techItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '40px',
    
    '&:before': {
      flex: '0 0 40px',
      content: '""',
      display: 'block',
      marginRight: '20px',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: '#fff',
      cursor: 'pointer',
    },

    '&:not(:last-child):after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: 'calc(100% - 44px + 36px)',
      top: '44px',
      left: 'calc(20px - 1px)',
      background: '#fff',
    },

    '&:hover:before, &.active:before': {
      background: [theme.palette.gradientBlue.main],
    },

    '&.active .tech-desc-p': {
      height: 'auto',
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      transitionProperty: 'height, visibility, opacity, transform',
      transitionDuration: '.3s, .3s, .5s, .5s',
    },
  },

  techDesc: {
    overflow: 'hidden',
  },

  techName: {
    marginBottom: '15px',
    cursor: 'pointer',

    '& p': {
      fontSize: '1.8rem',
      fontWeight: 700,
      lineHeight: 1.1,
    },
  },

  desc: {
    height: '0px',
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateY(-50px)',
    transitionProperty: 'height, visibility, opacity, transform',
    transitionDuration: '.3s, .3s, .5s, .5s',
  },

  [theme.breakpoints.up('md')]: {
    wrapper: {
      background: [theme.palette.background.default],
      position: 'relative',
      padding: '70px 0 15px',
      zIndex: 3,
    },

    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: 'calc(50% - 20px)',
      background: [theme.palette.gradientDark.main],
      borderRadius: '0px 20px 20px 0px',
      zIndex: '0',

      '& svg': {
        width: '100%',
        height: 'calc(100% - 60px)',
        position: 'absolute',
        bottom: 0,
        left: 0,
        opacity: .4,
      },
    },

    overlayRight: {
      position: 'absolute',
      top: 'calc(-50% + 50px)',
      right: '0',
      zIndex: '-1',
      opacity: .5,
    },

    content: {
      position: 'relative',
      zIndex: 1,
    },

    title: {
      marginBottom: '70px !important',
    },


    subtitle: {
      width: '80%',
    },

    techsBox: {
      color: [theme.palette.text.primary],
    },

    techItem: {
      
      '&:before': {
        background: [theme.palette.text.primary],
      },

      '&:not(:last-child):after': {
        background: [theme.palette.text.primary],
      },
    },

  },

}))

const Technologies = ({title, content, ...props}) => {

  const classes = useStyles()

  const [activeTech, setActiveTech] = useState(0)

  const onChangeTech = useCallback(() => {
    const allTechs = document.querySelectorAll('[data-tech]')

    allTechs.forEach(item => {
      item.classList.remove('active')
    })

    const newTech = document.querySelector(`[data-tech="${activeTech}"]`)

    newTech.classList.add('active')
  }, [activeTech])

  useEffect(() => {
    onChangeTech()
  }, [activeTech, onChangeTech])

  return (
    <Box
      component="section"
      className={classes.wrapper}
      id="technologies"
      {...props}
    >
      <Hidden smDown>
        <Box className={classes.overlay}>
          <Vector />
        </Box>
        <Box className={classes.overlayRight}>
          <StaticImage
            src="../../images/techs-bg.png"
            quality={85}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Технологии"
          />
        </Box>
      </Hidden>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className={classes.titleBox}>
                <Typography variant="h2" className={`${classes.title} title`}>{title}</Typography>
                <Typography variant="body1" className={`${classes.subtitle} subtitle`}>— Мы всегда идём в ногу со временем и в процессе разработки сайта используем самые последние технологии и инструменты</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.techsBox}>
                <ul className={classes.techList}>
                  {
                    content.map((tech, idx) => (
                      <li className={classes.techItem} key={`${tech.id}-${idx}`} data-tech={idx} onClick={() => setActiveTech(idx)} aria-hidden="true">
                        <div className={classes.techContent}>
                          <div className={classes.techName}>
                            <p>{tech.name}</p>
                          </div>
                          <div className={classes.techDesc}>
                            <p className={`${classes.desc} tech-desc-p`}>{tech.desc}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

Technologies.defaultProps = {
  title: 'технологии, которые мы используем',
  content: 
  [
    {
      name: 'Frontend',
      desc: 'React, Gatsby, Next.js, JavaScript, HTML, CSS, SCSS  и другие',
      id: 't1',
    },
    {
      name: 'Backend',
      desc: 'Node.js, PHP, GraphQL, MySQL',
      id: 't2',
    },
    {
      name: 'CMS',
      desc: 'Wordpress, Contentful, Netlify CMS, 1С, OpenCart, Drupal',
      id: 't3',
    },
    {
      name: 'Дизайн',
      desc: 'Figma, Sketch, Adobe Photoshop, Adobe Illustrator, Adobe InDesign и другие',
      id: 't4',
    },
    {
      name: 'Реклама и маркетинг',
      desc: 'Яндекс.Директ, Google Ads, Google Analytics, Яндекс.Метрика, Roistat, Comagic, SpyWords, Elama, Keys.so',
      id: 't5',
    },
    {
      name: 'CRM',
      desc: 'Битрикс 24, 1С-Фитнес, EnvyCRM, AmoCRM, Мегаплан, Mango CRM и другие',
      id: 't6',
    },
  ],
}

Technologies.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
}

export default Technologies
