import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
// import NumberFormat from 'react-number-format'

const endpoints = {
  contact: "/.netlify/functions/send-email",
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: [theme.palette.gradientDark.main],
    padding: "44px 30px 60px 30px",
    borderRadius: "40px",
  },

  input: {
    background: "#fff",
    borderRadius: "5px",

    "& .MuiInputBase-input": {
      fontSize: "18px",
    },
  },

  policy: {
    color: "#fff",
    textAlign: "center",
    marginBottom: "10px",
    lineHeight: 1.1,
    fontSize: "11px",

    "& a": {
      color: "inherit",
    },
  },

  btnBox: {
    "& button": {
      padding: "10px 10px",
    },
  },

  errorBox: {

    display: 'flex',
    flexDirection: 'column',

    '& p': {
      color: '#d40202',
      fontWeight: 500,
      padding: '5px',
      backgroundColor: [theme.palette.grey[200]],
      border: '1px solid #dadada',
      marginTop: '5px',
      borderRadius: '5px',

      '&::before': {
        display: 'inline',
        content: '"⚠ "',
      },
    },
  },

  formMessage: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
    
    '& p': {
      padding: '5px 10px',
      color: 'white',
      fontWeight: 500,
      borderRadius: '5px',
    },
  },

  errorMessage: {
    '& p': {
      backgroundColor: '#ff1717',
    },
  },
  successMessage: {
    '& p': {
      backgroundColor: '#2cb52a',
    },
  },

  [theme.breakpoints.up("lg")]: {
    wrapper: {
      padding: "44px 60px 60px 60px",
    },

    btnBox: {
      display: "flex",
      alignItems: "center",

      "& button": {
        margin: "0 0 0 15px",
      },
    },

    policy: {
      textAlign: "right",
      fontSize: "12px",
      margin: "0 15px 0 0",
    },
  },

  "@media only screen and (min-device-width : 768px) and (max-device-width : 1285px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2)": {
    btnBox: {
      flexDirection: "column",
    },

    policy: {
      textAlign: "center",
      margin: "0 0 15px 0",
    },

    wrapper: {
      padding: "34px 50px 50px 50px",
    },
  },
}))

const FormFooter = () => {

  const classes = useStyles()

  /**
   * Form submit
   */

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange"
  })

  const [formState, setFormState] = useState({
    successForm: false,
    errorForm: false,
  })

  const [submittedData, setSubmittedData] = useState({})

  // const onSubmit = (data) => console.log(data)

  const onSubmit = async (data, e) => {
    setSubmittedData(data)

    try {
      const response = await fetch(endpoints.contact, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response

        reset({ ...submittedData })

        setFormState({
          successForm: false,
          errorForm: true,
        })
        return
      }

      //all OK
      reset({ ...submittedData })

      setFormState({
        successForm: true,
        errorForm: false,
      })
    } catch (e) {
      //error
      console.error('Произошла Ошибка: ', e)
    }
  }

  /**
   * End Form submit
   */

  const telProps = {
    inputMode: "tel",
  }
  const mailProps = {
    inputMode: "email",
  }

  return (
    <Box className={classes.wrapper}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        name="Форма_футер"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        noValidate
      >
        <input type="hidden" name="form-name" value="Форма_футер" />
        <input type="hidden" {...register("Откуда форма")} value="Форма футер" />
        <Box className={classes.inputsBox} mb={3}>
          <Box className={classes.formControl}>
            <TextField
              {...register("Имя", {
                required: 'Напишите ваше имя'
              })}
              id="input-name-footer"
              placeholder="Ваше имя*"
              variant="outlined"
              color="primary"
              className={classes.input}
              size="medium"
              fullWidth
              margin="normal"
              type="text"
            />
            <Box className={classes.errorBox} mt={1}>
              <ErrorMessage
                errors={errors}
                name="Имя"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p key={type}>{message}</p>
                      ))
                    : null;
                }}
              />
            </Box>
          </Box>
          <Box className={classes.formControl}>
            <TextField
              {...register("Телефон", {
                required: 'Оставьте свой номер телефона',
                pattern: {
                  value: /\d+/,
                  message: "Можно использовать только цифры."
                },
                minLength: {
                  value: 11,
                  message: "Должно быть не менее 11 цифр."
                },
              })}
              id="input-phone-footer"
              placeholder="Номер телефона*"
              variant="outlined"
              color="primary"
              className={classes.input}
              size="medium"
              fullWidth
              margin="normal"
              type="tel"
              inputProps={telProps}
            />
            <Box className={classes.errorBox} mt={1}>
              <ErrorMessage
                errors={errors}
                name="Телефон"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p key={type}>{message}</p>
                      ))
                    : null;
                }}
              />
            </Box>
          </Box>
          <Box className={classes.formControl}>
            <TextField
              {...register("Почта", {
                required: 'Напишите вашу почту',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Некорректный адрес почты"
                }
              })}
              id="input-mail-footer"
              placeholder="Ваша почта*"
              variant="outlined"
              color="primary"
              className={classes.input}
              size="medium"
              fullWidth
              margin="normal"
              type="email"
              inputProps={mailProps}
            />
            <Box className={classes.errorBox} mt={1}>
              <ErrorMessage
                errors={errors}
                name="Почта"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p key={type}>{message}</p>
                      ))
                    : null;
                }}
              />
            </Box>
          </Box>
          <Box className={classes.formControl}>
            <TextField
              {...register("Комментарий")}
              id="input-comment-footer"
              placeholder="Кратко опишите проект..."
              variant="outlined"
              color="primary"
              className={classes.input}
              size="medium"
              fullWidth
              margin="normal"
              multiline={true}
              rows="5"
            />
          </Box>
        </Box>
        <Box className={classes.btnBox}>
          <Typography variant="body2" className={classes.policy}>
            Нажимая на кнопку, вы даете согласие на обработку персональных
            данных и соглашаетесь с{" "}
            <a href="/policy" target="_blank" rel="noopener noreferrer">
              политикой конфиденциальности
            </a>
            .
          </Typography>
          <Button type="submit" color="primary" variant="contained" fullWidth size="large">
            оставить заявку
          </Button>
        </Box>
        { formState.successForm && <Box className={`${classes.successMessage} ${classes.formMessage}`}><p>Форма успешно отправлена.</p></Box> }
        { formState.errorForm && <Box className={`${classes.errorMessage} ${classes.formMessage}`}><p>Произошла ошибка. Попробуйте еще раз.</p></Box> }
      </form>
    </Box>
  )
}

export default FormFooter


// const MaskedPhone = () => {

//   return (
//     <NumberFormat format="+7 (###) ###-##-##" mask="_"/>
//   )
// }