import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import FormFooter from '../forms/form-footer'

const useStyles = makeStyles((theme) => ({

  titleBox: {
    paddingBottom: '30px',
    marginBottom: '30px',
    borderBottom: '1px solid #414141',
  },

  h3: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: 1.2,

    '& span': {
      color: [theme.palette.primary.main],
      fontWeight: 800,
      textTransform: 'uppercase',
    },
  },

  formInfoText: {
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 1.4,
  },

  gridInfo: {
    marginBottom: '30px',
  },

  '@media (min-width: 900px)': {

    wrapper: {
      position: 'relative',
      zIndex: 10,

      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '340px',
        top: 'calc(50% - 170px)',
        left: 0,
        background: `url(/images/ascent-footer.svg) center center / contain no-repeat`,
        zIndex: 0,
      },
    },

    content: {
      position: 'relative',
      zIndex: 2,
    },

    titleBox: {
      border: 'none',
      paddingBottom: '0',
      marginBottom: '50px',
    },

    title: {
      marginBottom: '80px !important',
      textAlign: 'left',
    },

    h3: {
      textAlign: 'left',
      fontSize: '2rem',
    },

    formInfoText: {
      textAlign: 'left',
      fontSize: '1.5rem',
    },

    formBox: {
      padding: '0 80px',
    },
  },

  '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2)': {

    title: {
      fontSize: '2rem',
    },

    h3: {
      fontSize: '1.8rem',
    },
  },

  '@media (min-width: 900px) and (max-width: 1023px)': {
    content: {

      '& .grid-info-item': {
        maxWidth: '41.666667%',
        flexBasis: '41.666667%',
      },

      '& .grid-form-item': {
        maxWidth: '58.333333%',
        flexBasis: '58.333333%',
      }
    },
  },
}))

const FormSection = () => {

  const classes = useStyles()

  return (
    <Box id="form-section" component="section" className={classes.wrapper} py={{xs: 6, md: 7}}>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Grid container justify="space-between">
            <Grid item xs={12} md={5} className="grid-info-item">
              <Box className={classes.gridInfo}>
                <Box className={classes.titleBox}>
                  <Typography variant="h2" className={`${classes.title} title`}>есть <span>идея</span> проекта? положитесь на нас</Typography>
                  <h3 className={classes.h3}><span>«ACSENT»</span> — Ваш технологический партнер в разработке и продвижении сайта для бизнеса.</h3>
                </Box>
                <Box className={classes.formInfo}>
                  <Typography variant="body2" className={classes.formInfoText}>Заполните форму заявки со своими данными, и наш специалист свяжется с вами в ближайшее время.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={6} className="grid-form-item">
              <Box className={classes.formBox}>
                <FormFooter />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default FormSection
